.tinymce-link, #tinymce a {
    color: #0077E8;
    cursor: pointer;
}

.tox .tox-collection--list .tox-collection__item {
    padding: 8px 16px !important;
}
.tox .tox-tbtn:hover, .tox .tox-collection--list .tox-collection__item:hover, .tox .tox-collection--list .tox-collection__item--active {
    background-color: #F5F5F5 !important;
}
.tox .tox-tbtn--enabled {
 background: #E9E9E9;
}
.tox .tox-tbtn {
    width: 34px !important;
    cursor: pointer;
    margin-right: 8px !important;
}
.tox .tox-tbtn:last-child {
    margin-right: 0 !important;
}
.tox-sidebar-wrap {
    padding-left: 16px !important;
    padding-right: 8px !important;
    padding-bottom: 32px;
}
.tox .tox-tbtn__select-label, .tox-tbtn__select-chevron {
    display: none !important;
}
.tox-tbtn svg, .tox-toolbar__group svg {
    width: 16px;
    height: 16px;
}
.tox-tbtn svg path,
.tox-toolbar__group svg path,
.tox-toolbar__group svg rect,
.tox-tbtn--enabled svg,
.tox-tbtn--enabled svg path,
.tox-tbtn--enabled svg rect {
    fill: transparent !important;
}
.tox.tox-tinymce.tox-tinymce--toolbar-bottom.tox-tinymce--disabled:after {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1;
}
